<template>
  <div class="directories__container general">
    <CommonTitle :options="options" />
    <CommonTable :options="options" />
  </div>
</template>


<script>
import axios from 'axios';
import CommonTitle from '../../common/CommonTitle';
import CommonTable from '../../common/CommonTable';

export default {
  name: 'DifficultyMain',

  components: {
    CommonTitle,
    CommonTable,
  },

  data: () => ({
    options: {
      add: 'Добавить уровень', 
      actions: 'edit-remove-add', 
      styles: 'grid-area: 1 / 1 / 5 / 3', 
      id: 'difficulty', 
      request: 'difficulties', 
      name: 'Уровни сложности',
      table: {
        variables: ['name', 'points'],
        page: 0,
        limit: 10,
        total: 0,
        data: []
      },
      ths: [
        { id: 'name', name: 'Уровень', value: '', sort: true, sort_data: '', search: true, width: '35%' },
        { id: 'points', name: 'Бонусные баллы', value: '', sort: true, sort_data: '', search: true, width: '45%' },
        { id: '', name: '', width: '20%' },
      ],
    },
  }),

  mounted() {
    this.fetchDifficulties();
    this.$bus.$on('createSetting', ({ data }) => this.options.table.data.push(data));
    this.$bus.$on('editSetting', ({ data }) => {
      const setting = this.options.table.data.find(el => el._id === data._id);
      if (!setting) return;
      if (setting.name) setting.name = data.name;
      if (setting.description) setting.description = data.description;
      if (setting.score) setting.score = data.score;
      if (setting.points) setting.points = data.points;
      if (setting.min_time) setting.min_time = data.min_time;
      if (setting.max_time) setting.max_time = data.max_time;
    });
    this.$bus.$on('removeSetting', ({ data }) => {
      this.options.table.data = this.options.table.data.filter(el => el._id !== data);
    });
  },

  methods: {
    async fetchDifficulties() {
      try {
        const token = this.$store.getters.token;
        const headers = { token };
        const { data: { data, total } } = await axios('/api/difficulties', { headers });
        this.options.table = Object.assign(this.options.table, { data, total });
      } catch(err) {
        console.error(err);
      };
    },
  },
};
</script>


<style lang="scss" scoped>
.directories__container {
  width: 50%;
}
</style>